/*Temporary fix for the divider*/

.p-divider-solid.p-divider-horizontal:before {
    border-top-style: solid !important;
}
.p-divider-dashed.p-divider-horizontal:before {
    border-top-style: dashed !important;
}
.p-divider-solid.p-divider-vertical:before {
    border-left-style: solid !important;
}


.top-spacer{
    margin-top: 7px;
}

.pink-color {
    color: #E91E63 !important;
}

